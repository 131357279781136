import React, { useState, useEffect, useRef } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import MapComponent from './components/MapComponent';
import MenuContainer from './components/containers/MenuContainer';
import HighlightedRouteContainer from './components/containers/HighlightedRouteContainer';
import FilterContainer from './components/containers/FilterContainer';
import useFetchData from './components/hooks/useFetchData';
import './App.css';

function App() {
  const mapRef = useRef(null);
  const [highlightedRoute, setHighlightedRoute] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [selectedRatings, setSelectedRatings] = useState([1, 2, 3, 4, 5]);
  const [distressEnabled, setDistressEnabled] = useState(false);
  const [mapRefreshKey, setMapRefreshKey] = useState(0); // New state for refreshing the map

  const { groupedData, fetchData } = useFetchData();

  const adminUsers = ['W0tW3H4BS8cOFkRvNCTS4wuVdXB3', 'anotherAdminID'];
  const isAdmin = (user) => user && adminUsers.includes(user.uid);

  // Firebase Authentication setup
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user || null);
      setIsAuthChecked(true);

      if (user) {
        fetchData(user.uid); // Fetch data when user is authenticated
      } else {
        fetchData(null); // Clear data on logout
        setHighlightedRoute(null); // Reset highlighted route on logout
      }
    });
    return () => unsubscribe();
  }, [fetchData]);

  const handleRatingChange = (ratings) => setSelectedRatings(ratings);
  const handleDistressToggle = (enabled) => setDistressEnabled(enabled);

  const updateHighlightedRoute = () => {
    if (highlightedRoute?.video) {
      const updatedRouteData = groupedData[highlightedRoute.video];
      if (updatedRouteData) {
        setHighlightedRoute({
          ...highlightedRoute,
          coordinates: updatedRouteData.items,
          averageRating: updatedRouteData.items.reduce((acc, item) => acc + item.cvatRating, 0) / updatedRouteData.items.length
        });
      }
    }
  };

  // Update highlighted route whenever groupedData changes
  useEffect(() => {
    if (highlightedRoute) {
      updateHighlightedRoute();
    }
  }, [groupedData]);

  if (!isAuthChecked) {
    return <p>Loading...</p>;
  }

  return (
    <div className="App">
      <MenuContainer
        mapRef={mapRef}
        setMapRefreshKey={setMapRefreshKey} // Pass down setMapRefreshKey to MenuContainer
        isAdmin={isAdmin(currentUser)}
        highlightedRoute={highlightedRoute}
        setHighlightedRoute={setHighlightedRoute}
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        groupedData={groupedData}
        fetchData={(userID) => fetchData(userID, true)} // Force data refresh when needed
      />

      <div className="main-content">
        <div className="sidepanelContainer">
          <div className="filterContainer">
            <FilterContainer
              onRatingChange={handleRatingChange}
              onDistressToggle={handleDistressToggle}
            />
          </div>
          <div className="routeContainer">
            <HighlightedRouteContainer
              highlightedRoute={highlightedRoute}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              selectedRatings={selectedRatings}
            />
          </div>
        </div>
        
        <div className="mapContainer">
          <MapComponent 
            ref={mapRef} 
            key={mapRefreshKey} // Use mapRefreshKey to re-render the component
            selectedRatings={selectedRatings}
            distressEnabled={distressEnabled}
            isAdmin={isAdmin(currentUser)}
            currentUser={currentUser}
            setMapRefreshKey={setMapRefreshKey}
            groupedData={groupedData}
            fetchData={(userID) => fetchData(userID, true)} // Ensure map updates after fetchData is called
          />
        </div>
      </div>
    </div>
  );
}

export default App;
