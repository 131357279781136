import React, { useState } from 'react';
import logo from '../../uploads/logo.png';
import UploadPopup from '../Popups/UploadPopup';
import EditPopup from '../Popups/EditPopup';
import UserDropdown from '../Popups/UserLoginPopup';
import ManagePopup from '../Popups/ManagePopup';
import './MenuContainer.css';

const MenuContainer = ({
  mapRef,
  isAdmin,
  setHighlightedRoute,
  highlightedRoute,
  currentUser,
  groupedData,
  fetchData,
  setCurrentUser,
  setMapRefreshKey
}) => {
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [isManagePopupOpen, setIsManagePopupOpen] = useState(false);
  const [isUploadPopupOpen, setIsUploadPopupOpen] = useState(false);

  const deleteData = async (id) => {
    if (window.confirm(`Are you sure you want to delete video with ID ${id}?`)) {
      try {
        const response = await fetch(`https://140.203.17.132:443/data/${id}`, { method: 'DELETE' });
        if (!response.ok) {
          throw new Error('Failed to delete data');
        }
        
        // Clear highlighted route if it matches the deleted one
        if (highlightedRoute?.video === id) {
          setHighlightedRoute(null); // Unhighlight the route
        }
        
        // Refresh data to update the menu and map
        console.log("about to refresh data");
        fetchData(currentUser?.uid, true); // Force refresh data
        setMapRefreshKey(prevKey => prevKey + 1); // Trigger map refresh
      } catch (error) {
        console.error('Error deleting data:', error);
      }
    }
  };
  

  const handleFocusClick = (video) => {
    const coordinates = groupedData[video].items;
    if (mapRef.current) {
      mapRef.current.flyToCoordinates(coordinates);
    }
    setIsManagePopupOpen(false);
    setHighlightedRoute({ video, coordinates, averageRating: getAverageRating(coordinates) });
  };

  const getAverageRating = (coordinates) => {
    if (!coordinates || coordinates.length === 0) return 0;
    const totalRating = coordinates.reduce((acc, coord) => acc + coord.cvatRating, 0);
    return Math.round(totalRating / coordinates.length);
  };

  return (
    <div className="menuContainer">
      <div className="header">
        <div className="right-section">
          <img src={logo} alt="Logo" className="header-logo" />
          <h1 className="pave-title">PavAnalytics</h1>
        </div>
        <div className="topbar-buttons">
          {currentUser && isAdmin && (
            <button className="uploadButton" onClick={() => setIsUploadPopupOpen(true)}>Upload</button>
          )}
          <button className="manageButton" onClick={() => setIsManagePopupOpen(true)}>Manage</button>
        </div>
        <UserDropdown 
          mapRef={mapRef}
          fetchData={(userID) => fetchData(userID)}
          setCurrentUser={setCurrentUser}
          currentUser={currentUser}
        />
      </div>

      <ManagePopup
        groupedData={groupedData}
        isManagePopupOpen={isManagePopupOpen}
        setIsManagePopupOpen={setIsManagePopupOpen}
        handleFocusClick={handleFocusClick}
        deleteData={deleteData}
        currentUser={currentUser}
        isAdmin={isAdmin}
        setCurrentVideoId={setCurrentVideoId}
        setIsEditPopupOpen={setIsEditPopupOpen}
      />

      <UploadPopup
        isOpen={isUploadPopupOpen}
        onClose={() => {
          setIsUploadPopupOpen(false);
          fetchData(currentUser?.uid); // Refresh data after upload
        }}
        currentUser={currentUser}
      />

      <EditPopup
        isOpen={isEditPopupOpen}
        onClose={() => setIsEditPopupOpen(false)}
        currentVideoId={currentVideoId}
        groupedData={groupedData}
        fetchData={(userID) => fetchData(userID)}
        currentUser={currentUser}
      />
    </div>
  );
};

export default MenuContainer;
