import { useCallback } from 'react';
import { getColorByRating } from '../utils/mapUtils';

const usePlotCoordinates = (mapRef, selectedRatings) => {
  const plotCoordinates = useCallback((coordinates, video) => {
    if (!mapRef.current || !mapRef.current.isStyleLoaded()) {
      console.warn("Map or style is not fully loaded. Skipping plotCoordinates.");
      return;
    }

    // Clear existing layers and sources for the video
    try {
      if (mapRef.current.getSource(video)) {
        if (mapRef.current.getLayer(`${video}-points`)) {
          mapRef.current.removeLayer(`${video}-points`);
        }
        if (mapRef.current.getLayer(`${video}-line`)) {
          mapRef.current.removeLayer(`${video}-line`);
        }
        mapRef.current.removeSource(video);
      }
    } catch (error) {
      console.error("Error removing previous layers or source:", error);
    }

    // If coordinates is an empty array, stop here to effectively clear the map for this video
    if (!coordinates || coordinates.length === 0) return;

    // Ensure unique layer and source names by appending a timestamp if necessary
    const uniqueId = `${video}-${Date.now()}`;

    // Prepare GeoJSON features for points
    const points = coordinates.map(coord => ({
      type: 'Feature',
      geometry: { type: 'Point', coordinates: [coord.lng, coord.lat] },
      properties: {
        color: getColorByRating(coord.cvatRating),
        video,
        cvatRating: coord.cvatRating
      },
    }));

    // Prepare GeoJSON features for lines
    const maxDistance = 0.002;
    const lineSegments = [];
    for (let i = 1; i < coordinates.length; i++) {
      const prevCoord = coordinates[i - 1];
      const currentCoord = coordinates[i];
      const distance = Math.sqrt(
        Math.pow(currentCoord.lng - prevCoord.lng, 2) + Math.pow(currentCoord.lat - prevCoord.lat, 2)
      );

      if (distance <= maxDistance) {
        lineSegments.push({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: [
              [prevCoord.lng, prevCoord.lat],
              [currentCoord.lng, currentCoord.lat],
            ],
          },
          properties: {
            color: getColorByRating(currentCoord.cvatRating),
            video,
            cvatRating: currentCoord.cvatRating
          },
        });
      }
    }

    try {
      // Add source after a brief delay to ensure the removal is processed
      setTimeout(() => {
        mapRef.current.addSource(uniqueId, {
          type: 'geojson',
          data: { type: 'FeatureCollection', features: [...points, ...lineSegments] },
        });

        // Add line layer
        mapRef.current.addLayer({
          id: `${uniqueId}-line`,
          type: 'line',
          source: uniqueId,
          layout: { 'line-join': 'round', 'line-cap': 'round' },
          paint: {
            'line-color': ['get', 'color'],
            'line-width': 5,
            'line-opacity': 0.3,
          },
        });

        // Add points layer
        mapRef.current.addLayer({
          id: `${uniqueId}-points`,
          type: 'circle',
          source: uniqueId,
          paint: {
            'circle-radius': 6,
            'circle-color': ['get', 'color'],
            'circle-opacity': 0.3,
          },
        });
      }, 100); // Adjust delay as needed
    } catch (error) {
      console.error("Error adding new layers or source:", error);
    }
  }, [mapRef, selectedRatings]);

  return plotCoordinates;
};

export default usePlotCoordinates;
