import React, { useEffect, useRef, useImperativeHandle, forwardRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import usePlotCoordinates from './hooks/usePlotCoordinates';
import useDistressMarkers from './hooks/useDistressMarkers';
import 'mapbox-gl/dist/mapbox-gl.css';
import { flyToCoordinates } from './utils/mapUtils';

mapboxgl.accessToken = 'pk.eyJ1IjoiZWRkaWVzaGVlaHkiLCJhIjoiY2w1bWd3OWlyMHRuNjNkbm1ybXN4cHFwYiJ9.kcDH28yltJvjN3004Pvjtg'; // Replace with your Mapbox access token
const mapStyle = 'mapbox://styles/mapbox/streets-v11';

const MapComponent = forwardRef(({ selectedRatings, distressEnabled, isAdmin, currentUser, groupedData, fetchData, setMapRefreshKey }, ref) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [mapIsReady, setMapIsReady] = useState(false);
  const [distressMarkers, setDistressMarkers] = useState([]);
  const [routeIds, setRouteIds] = useState([]); // Track added routes for easy removal

  const plotCoordinates = usePlotCoordinates(mapRef, selectedRatings);
  const { plotDistressMarkers, clearDistressMarkers } = useDistressMarkers(mapRef, isAdmin, setMapRefreshKey);

  const resetMapStyle = () => {
    if (mapRef.current) {
      mapRef.current.setStyle(mapStyle); // Reset the map style to clear all layers and sources
      mapRef.current.once('styledata', () => {
        setMapIsReady(true); // Ensure map is ready for further actions
      });
    }
  };

  useImperativeHandle(ref, () => ({
    plotCoordinates,
    flyToCoordinates: (coordinates) => flyToCoordinates(coordinates, mapRef),
    refreshData: () => fetchData(currentUser?.uid),
  }));

  useEffect(() => {
    if (!mapRef.current && mapContainerRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: mapStyle,
        center: [-6.2603, 53.3498],
        zoom: 7,
      });

      mapRef.current.on('load', () => {
        setMapIsReady(true); // Set map as ready when initial load is complete
      });

      mapRef.current.on('styledata', () => {
        if (mapRef.current.isStyleLoaded()) {
          setMapIsReady(true); // Confirm map style is fully loaded
        }
      });
    }
  }, []);

  useEffect(() => {
    if (mapIsReady) {
      if (!currentUser?.uid) {
        console.log("User logged out or no userID provided, resetting map style.");
        resetMapStyle(); // Reset the map style on logout to clear routes
        setDistressMarkers([]);
        return;
      }

      // Plot coordinates if there is groupedData
      if (groupedData && Object.keys(groupedData).length > 0) {
        const newRouteIds = Object.keys(groupedData).map((video) => {
          const coordinates = groupedData[video].items;
          const uniqueId = `${video}-${Date.now()}`;
          if (coordinates && coordinates.length > 0) {
            plotCoordinates(coordinates, uniqueId);
          }
          return uniqueId;
        });
        setRouteIds(newRouteIds); // Store the new unique route IDs for later removal
      }
    }
  }, [mapIsReady, currentUser, groupedData, plotCoordinates]);

  useEffect(() => {
    const fetchDistressData = async () => {
      try {
        const response = await fetch('https://140.203.17.132:443/fetch-distress');
        const data = await response.json();
  
        // Log raw data for comparison
        console.log("Raw distress data:", data);
  
        // Filter data to ensure valid latitude and longitude
        const validData = data.filter((item) => {
          const [lat, lng] = item.location.split(',').map(coord => parseFloat(coord.trim()));
  
          // Check if lat and lng are valid numbers
          const isValid = !isNaN(lat) && !isNaN(lng);
          if (!isValid) {
            console.warn(`Invalid coordinates skipped: ${item.location}`);
          }
          return isValid;
        });
  
        // Log valid data to confirm correct filtering
        console.log("Valid distress data after filtering:", validData);
  
        // Plot distress markers if valid data is found
        if (validData.length > 0) {
          plotDistressMarkers(validData, setDistressMarkers);
        }
      } catch (error) {
        console.error('Error fetching distress data:', error);
      }
    };
  
    if (mapRef.current && distressEnabled) {
      fetchDistressData();
    } else if (mapRef.current && !distressEnabled) {
      clearDistressMarkers(setDistressMarkers);
    }
  }, [distressEnabled, plotDistressMarkers, clearDistressMarkers]);  

  return <div ref={mapContainerRef} style={{ width: '100%', height: '100%' }} />;
});

export default MapComponent;
