import React, { useState } from 'react';
import './UploadPopup.css';

const UploadPopup = ({ isOpen, onClose, currentUser }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const userUUID = currentUser ? currentUser.uid : "User not logged in";

    const formData = new FormData();
    formData.append('file', file);
    formData.append('userUUID', userUUID); // Adding the UUID to the form data

    const xhr = new XMLHttpRequest();

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = Math.round((event.loaded / event.total) * 100);
        setUploadProgress(percentComplete);
        setLoadingMessage('Uploading...');
        console.log(`Upload progress: ${percentComplete}%`);

        if (percentComplete === 100) {
          setLoadingMessage("Processing video, this might take a few minutes...");
        }
      }
    };

    xhr.onloadstart = () => {
      setIsLoading(true);
      setUploadProgress(0);
      setLoadingMessage('Uploading...');
      console.log('Upload started');
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        console.log('Upload complete');
        setLoadingMessage('Upload complete');
        setIsLoading(false);
      } else {
        alert(`Failed to upload file: ${xhr.statusText}`);
        setIsLoading(false);
        setLoadingMessage('');
      }
    };

    xhr.onloadend = () => {
      console.log('Upload ended');
      setIsLoading(false);
    };

    xhr.onerror = () => {
      alert('Upload failed, please check your network connection.');
      setIsLoading(false);
      setLoadingMessage('');
    };

    // Use the /demo_endpoint for uploading the file
    xhr.open('POST', `https://140.203.17.132:443/demo_endpoint`, true);
    xhr.send(formData);
  };

  if (!isOpen) return null; // Don't render if the popup is not open

  return (
    <div className="popup">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>X</button>
        <div className="upload-container">
          <label htmlFor="file-upload" className="custom-file-upload">
            Choose file
          </label>
          <input
            id="file-upload"
            type="file"
            className="fileInput"
            accept="video/*"
            onChange={handleVideoUpload}
            disabled={isLoading} // Disable input while uploading
          />
          {isLoading && (
            <div className="progress-bar-container">
              <div className="progress-bar" style={{ width: `${uploadProgress}%` }}>
                {uploadProgress}%
              </div>
            </div>
          )}
          <p>{loadingMessage}</p>
        </div>
      </div>
    </div>
  );
};

export default UploadPopup;
